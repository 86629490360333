section {
  padding: 30px 0 30px 0;
  overflow: hidden;
}

.weekend-details {
  padding-top: 45px;
}
.weekend-details section {
  padding: 3px 0px !important;
}

.intro-para {
  width: 50%;
  margin: auto;
  text-align: center;
  padding: 10px 0;
}
@media (min-width: 200px) and (max-width: 991px) {
  .intro-para {
    width: 100%;
  }
}

/* @media (min-width: 576px) and (max-width: 1400px) {
  .weekend-details .trip-content {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
} */
