.sticky-side-bar {
  position: fixed;
  bottom: 0;
  right: 15px;
  z-index: 1000;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 5px 6px 7px #666;
}

.sticky-side-bar a {
  position: fixed;
  top: 30%;
  right: -83px;
  z-index: 9999;
  color: #fff;
  background: #25d366;
  padding: 10px 15px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 25px 0 0 25px;
  box-shadow: 0px 9px 11px rgb(0 0 0 / 50%);
  text-shadow: 1px 1px #39841f;
  font-size: 18px;
}

.sticky-side-bar a:hover {
  background-color: #1e9c4c;
  color: #fff;
  right: 0px;
}

.sticky-side-bar .whatsapp i {
  font-size: 30px;
  position: relative;
  top: 3px;
}
