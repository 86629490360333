/* rotating animation  */

#loading {
  animation: rotation 2s infinite linear;
  -webkit-animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(-359deg);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(-359deg);
  }
}
