.contact-box {
  box-sizing: content-box;
  padding: 20px 15px;
  box-shadow: 0px 2px 12px rgb(0 0 0 / 8%) !important;
  position: relative;
  background: #fff;
  margin-bottom: 20px;
}

.contact-box h3 {
  font-size: 16px;
  text-align: center;
  margin-bottom: 0px;
}

.contact-box h3 span {
  font-size: 22px;
}

.contact-box p {
  text-align: center;
}

.btnwidth .btn,
.whatsapp-contact .btn,
.call-now-contact .btn {
  width: 100%;
  padding: 5px 0;
  margin-bottom: 10px;
}
